import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Switch,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ConnectionString } from 'connection-string';
import { colors } from 'styles/colors';

import { SqlCloudStorageConfig } from 'types/api';

import CustomFormControl from './components/CustomFormControl';
import InputPassword from './components/InputPassword';
import NamespaceBanner from './components/NamespaceBanner';
import PatternsIP from './components/PatternsIP';
import SslFormElement from './components/SslFormElement';
import { ExtraConfig, SpecificDatabaseProps } from './DatabaseForm';

type SqlConfig = SqlCloudStorageConfig & ExtraConfig;

const SqlForm = ({ storageType, onSubmit, GoBack, storage, isLoading }: SpecificDatabaseProps) => {
  const form = useForm<SqlConfig>({
    defaultValues: {
      ...(storage?.config as SqlConfig),
      name: storage?.name,
      useSSH: storage?.config ? !!(storage?.config as SqlConfig).ssh_tunnel_config : false,
      useSSL: storage?.config ? !!(storage?.config as SqlConfig).ssl_config : false,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = form;

  const sshEnabled = watch('useSSH');

  const passwordRequired = !storage; // password is required when creating but not when updating

  const toast = useToast();
  const [showParseUI, setShowParseUI] = useState(false);
  const [connectionString, setConnectionString] = useState('');

  const ParseConnectionString = () => {
    try {
      const parsed = new ConnectionString(connectionString);
      reset({
        host: parsed.hostname,
        port: parsed.port,
        username: parsed.user,
        password: parsed.password,
        database: parsed.path && parsed.path.length > 0 ? parsed.path[0] : '',
      });
    } catch (e) {
      console.warn(e);
      toast({
        title: 'Error parsing database connection string',
        description: (e as Error).toString(),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <VStack gap={2}>
        <CustomFormControl
          label="Name"
          isRequired={true}
          isInvalid={!!errors?.name}
          errorMessage={errors?.name?.message}
        >
          <Input {...register('name', { required: true, maxLength: 32 })} />
        </CustomFormControl>
        <Divider style={{ borderColor: colors.gray[400] }} my={3} />
        {!storage && (
          <Button
            variant={'ghost'}
            px={3}
            py={2}
            ml={-3}
            mt={-1}
            mb={1}
            onClick={() => setShowParseUI(true)}
            mr={'auto'}
          >
            Use database connection string
          </Button>
        )}
        {showParseUI && (
          <>
            <Flex display={{ base: 'block', sm: 'flex' }} width={'100%'}>
              <FormLabel minWidth={'150px'} maxWidth={'150px'} my={'auto'}>
                Connection string
              </FormLabel>

              <Input
                onChange={(e) => setConnectionString(e.target.value)}
                placeholder="postgres://YourUserName:YourPassword@YourHostname:5432/YourDatabaseName"
              />
              <Button
                variant={'outline'}
                height={'34px'}
                ml={3}
                width={'100px'}
                colorScheme={'gray'}
                color={'text1'}
                onClick={() => ParseConnectionString()}
              >
                Parse
              </Button>
            </Flex>
            <Divider style={{ borderColor: colors.gray[400] }} my={3} />
          </>
        )}
        <CustomFormControl
          label="Host"
          isRequired={true}
          isInvalid={!!errors?.host}
          errorMessage={errors?.host?.message}
        >
          <Input
            {...register('host', {
              required: true,
            })}
          />
        </CustomFormControl>
        <CustomFormControl label="Port" isRequired={true} isInvalid={!!errors?.port}>
          <Input
            {...register('port', {
              required: true,
              pattern: {
                value:
                  /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                message: 'This does not look like a port',
              },
            })}
          />
        </CustomFormControl>
        <CustomFormControl label="Username" isRequired={true} isInvalid={!!errors?.username}>
          <Input
            {...register('username', {
              required: true,
            })}
          />
        </CustomFormControl>
        <CustomFormControl
          label="Password"
          isRequired={!storage}
          isInvalid={!!errors?.password}
          helpText={
            !!storage && 'Password is readonly. If you need to change it, enter your new password.'
          }
          helpTextAlign={'right'}
        >
          <InputPassword
            {...register('password', {
              required: passwordRequired,
            })}
          />
        </CustomFormControl>
        <CustomFormControl label="Database" isRequired={true} isInvalid={!!errors?.database}>
          <Input
            {...register('database', {
              required: true,
            })}
          />
        </CustomFormControl>
        <CustomFormControl label="Use SSH?" isInvalid={!!errors?.useSSH} display={'flex'} mt={2}>
          <Controller
            name="useSSH"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Switch
                isChecked={value}
                onChange={(event) => onChange(event.currentTarget.checked)}
              />
            )}
          />
        </CustomFormControl>
        {sshEnabled && (
          <VStack ml={5} width={'100%'} gap={2}>
            <CustomFormControl
              label="SSH Host"
              isRequired={true}
              isInvalid={!!errors?.ssh_tunnel_config?.ssh_host}
              errorMessage={errors?.ssh_tunnel_config?.ssh_host?.message}
            >
              <Input {...register('ssh_tunnel_config.ssh_host')} />
            </CustomFormControl>

            <CustomFormControl
              label="SSH Port"
              isRequired={true}
              isInvalid={!!errors?.ssh_tunnel_config?.ssh_port}
            >
              <Input
                {...register('ssh_tunnel_config.ssh_port', {
                  pattern: {
                    value:
                      /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
                    message: 'This does not look like a port',
                  },
                })}
              />
            </CustomFormControl>

            <CustomFormControl
              label="SSH Username"
              isInvalid={!!errors?.ssh_tunnel_config?.ssh_username}
            >
              <Input {...register('ssh_tunnel_config.ssh_username')} />
            </CustomFormControl>

            <CustomFormControl
              label="SSH Password"
              isInvalid={!!errors?.ssh_tunnel_config?.ssh_password}
              helpText={
                !!storage &&
                'Password is readonly. If you need to change it, enter your new password.'
              }
              helpTextAlign={'right'}
            >
              <InputPassword {...register('ssh_tunnel_config.ssh_password')} />
            </CustomFormControl>
          </VStack>
        )}

        {/* @ts-ignore */}
        <SslFormElement storage={storage} form={form} />

        <NamespaceBanner />
        <PatternsIP />
        <Flex width={'100%'} justifyContent={'flex-end'}>
          {GoBack && (
            <Button
              variant={'outline'}
              borderColor={'#BBBEC7'}
              color={'text2'}
              bg={'white'}
              _hover={{ borderColor: '#60687D' }}
              _active={{ bg: '#F4F4F6' }}
              onClick={() => GoBack()}
              my={'auto'}
              size={'sm'}
              borderRadius={'md'}
              ml={'auto'}
              mr={3}
            >
              Back
            </Button>
          )}
          <Button
            isDisabled={!storageType}
            type="submit"
            size={'sm'}
            borderRadius={'md'}
            isLoading={isLoading}
          >
            {storage ? 'Update' : 'Connect'} {storageType?.label} Database
          </Button>
        </Flex>
      </VStack>
    </form>
  );
};

export default SqlForm;
